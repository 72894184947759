import React from 'react'

const Home = () => {
  return (
    <div className='w-full flex justify-center items-center flex-col'>
        <p className='mt-2 rounded-lg border-2 border-black text-3xl'>🔒09/08 - 2024🔒</p>
        <img className='rounded-lg border-2 border-black mt-2' src="https://s13.gifyu.com/images/SPmoS.gif" alt="IMG 2059" border="0" />
    </div>
  )
}

export default Home