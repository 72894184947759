import React from 'react'

const Navbar = () => {
  return (
    <div className='w-full flex justify-center'>
        <div className='w-[95%] h-16 bg-red-200 rounded mt-2 border-b-2 border-red-500 flex justify-center items-center'>
            <p className='text-black'>Hulie forever❤️</p>
        </div>

    </div>
  )
}

export default Navbar