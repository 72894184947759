import React from 'react'

const Gallery = () => {
  return (
    <div className='w-full flex items-center justify-center'>
      
        <div className='flex flex-col items-center justify-center border-4 border-black w-[540px] h-fit'>
            <div className='flex flex-row items-center justify-center'>
                <img className='object-cover w-[180px] h-[180px] rounded-lg border-4 border-pink-400 mt-2' src="https://i.ibb.co/G24tqjs/IMG-2120.jpg" alt="IMG 2059" border="0" />
                <img className='object-cover w-[180px] h-[180px] rounded-lg border-4 border-blue-600 mt-2' src="https://i.ibb.co/KGk5Np1/IMG-2104.jpg" alt="IMG 2059" border="0" />
            </div>
            <div className='flex flex-row items-center justify-center'>
                <img className='object-cover w-[180px] h-[180px] rounded-lg border-4 border-blue-600 mt-2' src="https://i.ibb.co/YfQRQtd/IMG-2072.jpg" alt="IMG 2059" border="0" />
                <img className='object-cover w-[180px] h-[180px] rounded-lg border-4 border-pink-400 mt-2' src="https://i.ibb.co/ysqP94m/IMG-1563.jpg" alt="IMG 2059" border="0" />
            </div>
        </div>
    </div>
  )
}

export default Gallery