import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/home';
import Navbar from './Pages/navbar';
import React, { useState } from 'react';
import Gallery from './Pages/gallery';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    const correctPassword = "hulie"; // Set your password here
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };
  if (isAuthenticated) {
    return (
      <>
      <Navbar />
      <Gallery />

      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>

      <div className='flex w-full justify-center items-center'>
        <img className='w-[380px] h-[380px border-4 border-purple-400]' src="https://i.ibb.co/5Y96WtP/beautifulpicture.png" alt="beautifulpicture" />
      </div>

      
      </>
    );
  } else {
  return (
    <div style={{ textAlign: "center", marginTop: "20%" }}>
    <h1>Password Protected</h1>
    <p>Enter the password to access this site:</p>
    <input className='text-black'
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      placeholder="Password"
      style={{
        padding: "10px",
        fontSize: "16px",
        borderRadius: "4px",
        marginBottom: "10px",
      }}
    />
    <br />
    <button
      onClick={handleLogin}
      style={{
        padding: "10px 20px",
        fontSize: "16px",
        borderRadius: "4px",
        cursor: "pointer",
      }}
    >
      Login
    </button>
  </div>
  );}
}

export default App;
